<template>
  <div>
    <v-form>
      <p class="mb-3">
        Examine the
        <chemical-latex content="^1H" />
        spectrum of compound C.
      </p>
      <p class="mb-3">Given is a table summarizing the spectral data.</p>
      <v-simple-table class="mb-3">
        <tr>
          <th></th>
          <th style="text-align: left">ppm</th>
          <th style="text-align: left">Integration</th>
          <th style="text-align: left">Splitting</th>
        </tr>
        <tr v-for="option in tableOptions" :key="option">
          <td v-for="part in option" :key="part">{{ part }}</td>
        </tr>
      </v-simple-table>
      <p class="mb-3">
        Signal A (at 11.00 ppm) is characteristic for which functional group? (Spelling counts!)
      </p>
      <p>
        functional group:
        <v-text-field v-model="inputs.studentAnswer" style="display: inline-block; width: 15em" />
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';

export default {
  name: 'ChemUCI51LBA2_M3Q5a',
  components: {ChemicalLatex, StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        studentAnswer: null,
      },
      tableOptions: [
        ['A', '11.00', '1 H', 'singlet'],
        ['B', '2.75', '1 H', 'multiplet'],
        ['C', '1.10', '6 H', 'doublet'],
      ],
    };
  },
};
</script>
